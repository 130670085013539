import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api";
import { common, config } from "helpers";

function GoogleMaps(props) {
  // state
  const [markerText] = useState(props.markerText);
  const [searchInput] = useState(props.search);
  const [dragMarker] = useState(props.dragMarker);
  const [clickMarker] = useState(props.clickMarker);
  const [zoom] = useState(props.zoom);

  const [info, setInfo] = useState(false);
  const [search, setSearch] = useState("");
  const [coordinate, setCoordinate] = useState({
    lat: props.coordinate.lat,
    lng: props.coordinate.lng,
  });
  const [autoInput, setAutoInput] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.mapApiKey,
    libraries: ["places"],
  });

  // function
  const onClickMap = (e) => {
    if (clickMarker) {
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();
      setCoordinate({ lat, lng });
      setSearch("");
      props?.onSuccess({
        lat,
        lng,
      });
    }
  };

  const onPlaceChanged = () => {
    let res = autoInput.getPlace();
    if (res?.geometry) {
      let lat = res.geometry.location.lat();
      let lng = res.geometry.location.lng();
      setCoordinate({ lat, lng });
      props?.onSuccess({
        lat,
        lng,
      });
    }
  };

  const onMarketDragEnd = (e) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    setCoordinate({ lat, lng });
    setSearch("");
    props?.onSuccess({
      lat,
      lng,
    });
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "400px",
      }}
      center={coordinate}
      zoom={zoom}
      onClick={onClickMap}
      onLoad={() => setInfo(props.markerShow)}
    >
      <Marker
        position={coordinate}
        draggable={dragMarker}
        onDragEnd={onMarketDragEnd}
        onClick={() => setInfo(true)}
      >
        {info && markerText ? (
          <InfoWindow onCloseClick={() => setInfo(false)}>
            <div>{props.markerText}</div>
          </InfoWindow>
        ) : null}
      </Marker>
      {searchInput && (
        <Autocomplete
          onLoad={(e) => setAutoInput(e)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            style={common.mapStyle()}
            placeholder="Search place, address, landmark etc..."
            value={search}
            onBlur={(e) => setSearch(e.target.value)}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Autocomplete>
      )}
    </GoogleMap>
  ) : null;
}

export default GoogleMaps;
