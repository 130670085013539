import React, { useState, useEffect, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Modal from "react-modal";
// includes
import { api, common, message } from "helpers";
import StatusBar from "elements/statusBar";
import moment from "moment";
import CommonStudentView from "includes/studentView";

function Info() {
  // props
  let { id } = useParams();
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [status, setStatus] = useState("process");
  const [courseStatus, setCourseStatus] = useState(null);
  const [fields, setFields] = useState({});
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let status = "success";
    let data = {
      url: "application",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status !== 200) {
        status = "error";
      } else {
        setResult(res.data);
      }
      setStatus(status);
    });
  };

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Application Info
            </h3>
          </div>
        </div>
      </div>

      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            <div className="profile-notification">
              <div className="row d-flex align-items-center student-filter-header">
                <div className="col-md-4 d-flex justify-content-start">
                  <div className="p-3">
                    <div className="subheaddetitle">Profile</div>

                    <Link
                      to={`/application/profile/${id}`}
                      className="date-time d-flex justify-content-start maintitle"
                    >
                      {result.profile.firstName} {result.profile.lastName}
                    </Link>
                  </div>
                </div>
                <div className="col-md-8 d-flex justify-content-end">
                  <div className="p-3">
                    <div className="date-time d-flex justify-content-start">
                      ID
                    </div>
                    <div className="subheaddet">{result.refId}</div>
                  </div>
                  <div className="p-3">
                    <div className="date-time d-flex justify-content-start">
                      Date and Time
                    </div>
                    <div className="subheaddet">
                      {moment(result.createdAt).format("MMMM Do YYYY")} |{" "}
                      {moment(result.createdAt).format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CommonStudentView
              id={id}
              formModule="all"
              formType="application"
              formPreview={true}
              onlyView={true}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
export default Info;
