import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import StatusBar from "elements/statusBar";
import { api, common, config, types } from "helpers";
import moment from "moment";

function Info() {
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [count, setCount] = useState(0);
  const [error, setError] = useState(0);
  const [result, setResult] = useState({
    plan: [],
    info: {},
    profile: {},
    subscription: {},
  });
  // effect
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (count === 3) {
      if (error === 0) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    }
  }, [count]);

  // init
  const initData = () => {
    getPlan();
    getPayment();
    getProfile();
  };

  // api
  const getPlan = () => {
    let data = {
      url: "agent_plan",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let data = res.data?.data;
        let dataA = {};
        data.map((i) => {
          dataA[i._id] = i.title;
        });
        setResult((prev) => ({ ...prev, plan: data, subscription: dataA }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const getPayment = () => {
    let data = {
      url: "agent_payment",
      query: "?limit=1&offset=1&sortBy=_id&sortDir=desc",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, payment: res?.data?.data?.[0] }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const getProfile = () => {
    let data = {
      url: "agent_profile",
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, profile: res.data }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const isUpgrade = (id) => {
    let obj = Object.keys(result.subscription);
    return obj.indexOf(id) > obj.indexOf(result.payment?.planId?._id);
  };

  const planStatus = () => {
    let status = result.payment?.status;
    let res = {
      title: "",
      date: "",
      notes: "",
    };
    if (status === "active") {
      res = {
        title: "Expired On",
        date: moment(result.payment.endDate).format(config.elements.dp.B),
        notes: "Your plan is active",
      };
    } else if (status === "grace") {
      res = {
        title: "Grace Period Till",
        date: moment(result.payment.graceDate).format(config.elements.dp.B),
        notes: "You are in Grace period please renew your plan",
      };
    } else if (status === "expired") {
      res = {
        title: "Expired At",
        date: moment(result.payment.graceDate).format(config.elements.dp.B),
        notes: "Your plan has been expired please activate the plan",
      };
    } else if (status === "free") {
      res = {
        title: "Plan Type",
        date: "Free",
        notes: "Your are in BASIC plan now please upgrade to get more features",
      };
    }
    return res;
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <h1 className="page-title ps-4">Your Account Information</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Business Information
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Profile</p>
                        <p className="et-field-value mb-2">
                          {`${result.profile.firstName} ${result.profile.lastName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.profile.logo
                              ? common.cloudImg(result.profile.logo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Info</p>
                        <p className="et-field-value mb-2">
                          {`${result.profile.businessName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.profile.businessLogo
                              ? common.cloudImg(result.profile.businessLogo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    {[
                      {
                        name: "Email",
                        key: "email",
                        type: "string",
                      },
                      {
                        name: "Designation",
                        key: "designation",
                        type: "string",
                      },
                      {
                        name: "Business Address",
                        key: "businessAddress",
                        type: "string",
                      },
                      {
                        name: "Business Contact Number",
                        key: "businessContactNo",
                        type: "mobile",
                      },
                      {
                        name: "Business Registration Number",
                        key: "businessRegNumber",
                        type: "string",
                      },
                      {
                        name: "Your Recruitment Methods",
                        key: "recruitMethod",
                        type: "string",
                      },
                      {
                        name: "Number of students recruited for last year",
                        key: "noOfStudentRecruitLastYear",
                        type: "string",
                      },
                      {
                        name: "Number of staffs working",
                        key: "noOfStaffWorking",
                        type: "string",
                      },
                      {
                        name: "Other countries or branches",
                        key: "otherCountryBranch",
                        type: "string",
                      },
                      {
                        name: "Country",
                        key: "countryOfOperation",
                        type: "select",
                      },
                      {
                        name: "Province",
                        key: "province",
                        type: "select",
                      },
                      {
                        name: "City",
                        key: "city",
                        type: "select",
                      },
                    ].map((i, k) => (
                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                        key={k}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">{i.name}</p>
                          <p className="et-field-value">
                            {i.type === "mobile" ? (
                              <span>
                                {`${result.profile.mobileCode} ${result.profile.businessContactNo}`}
                              </span>
                            ) : (
                              common.readable(i.type, result.profile[i.key])
                            )}
                          </p>
                        </div>
                      </li>
                    ))}

                    <li className="col-md-8 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Description</p>
                        <p className="et-field-value">
                          {result.profile.description || "-"}
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Document</h3>
                  </div>

                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Registration</p>
                        {result.profile.businessRegDocs.length === 0 && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result.profile.businessRegDocs.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>

                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Proof of Identity</p>
                        {result.profile.proofIdentityDocs.length === 0 && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result.profile.proofIdentityDocs.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Reference</h3>
                  </div>

                  {result.profile.reference.length === 0 && (
                    <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                      <div className="et-vc-left">
                        <p className="et-vc-label">No reference found</p>
                      </div>
                    </div>
                  )}

                  {result.profile.reference.map((i, k) => (
                    <Fragment key={k}>
                      <div className="et-vc-step-info d-flex align-items-center justify-content-between et-view-border-bottom">
                        <div>
                          <span className="et-vc-step me-3">0{++k}</span>
                          <span className="et-vc-step-title">Reference</span>
                        </div>
                      </div>
                      <ul className="et-vc-list row">
                        {[
                          {
                            name: "Full Name",
                            key: "fullName",
                            type: "string",
                          },
                          {
                            name: "Designation",
                            key: "designation",
                            type: "string",
                          },
                          {
                            name: "Email Address",
                            key: "email",
                            type: "string",
                          },
                        ].map((cItem, cKey) => (
                          <li
                            className={`col-md-4 et-view-border-bottom et-view-border-right`}
                            key={cKey}
                          >
                            <div className="et-list-inner">
                              <p className="et-field-name">{cItem.name}</p>
                              <p className="et-field-value">
                                {common.readable(cItem.type, i[cItem.key])}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))}

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Subscription & Payment
                    </h3>
                  </div>

                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-3 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Current Plan</p>
                        <p className="et-field-value">
                          <span className="plan-lab-current">
                            {result.payment?.planId?.title}
                          </span>
                        </p>
                      </div>
                    </li>
                    {[1].map((i, k) => {
                      let ps = planStatus();
                      return (
                        <Fragment key={k}>
                          <li
                            className={`col-md-3 et-view-border-bottom et-view-border-right`}
                          >
                            <div className="et-list-inner">
                              <p className="et-field-name">{ps.title}</p>
                              <p className="et-field-value">{ps.date}</p>
                            </div>
                          </li>
                          {ps.notes && (
                            <li
                              className={`col-md-6 et-view-border-bottom et-view-border-right`}
                            >
                              <div className="et-list-inner">
                                <p className="et-field-name">Notes</p>
                                <p className="et-field-value">{ps.notes}</p>
                              </div>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  </ul>

                  <div className="row">
                    <div className="col-md-12">
                      {status !== "success" ? (
                        <StatusBar status={status} message="default" />
                      ) : (
                        <div className="row m-0">
                          <div className="col-lg-12 col-md-12">
                            <div className="row bgsubscript">
                              <div className="col-lg-12 col-md-12 subscripthead">
                                Subscription
                              </div>
                              <div className="row table-responsive">
                                <table className="uncollaps">
                                  <tbody>
                                    <tr>
                                      <th style={{ width: "50px" }}></th>
                                      <th className="firsthead"></th>
                                      {result.plan.map((i, k) => (
                                        <th className="bgheader" key={k}>
                                          <div className="bghead">
                                            <p className="bgheadclr">
                                              {i.title}
                                            </p>
                                            <div
                                              className={`SubscriptionCnt ${i.style}`}
                                            >
                                              <p>{i.billingTitle}</p>
                                              <p>{i.commission}</p>
                                              <Fragment>
                                                {i.upgrade ? (
                                                  <p className="plan-upgrade">
                                                    {i.currencySymbols +
                                                      i?.upgrade.grandTotal}
                                                    <span>
                                                      {i.currencySymbols +
                                                        i.price}
                                                    </span>
                                                  </p>
                                                ) : (
                                                  <p className="plan-upgrade">
                                                    {i.currencySymbols +
                                                      (i?.price || 0)}
                                                  </p>
                                                )}
                                              </Fragment>
                                            </div>
                                          </div>
                                        </th>
                                      ))}
                                    </tr>
                                    {Object.entries(types.planCategory).map(
                                      ([m, n]) => (
                                        <tr key={n}>
                                          <td style={{ width: "20px" }}></td>
                                          <td className="topichead lastborder">
                                            {n}
                                          </td>
                                          {result.plan.map((i, k) => (
                                            <Fragment key={k}>
                                              {i.access.includes(m) ? (
                                                <td>
                                                  <div className="SubscriptionCnt1 lastborder">
                                                    <div className="bghead1">
                                                      <div>
                                                        <p className="register-step-head1">
                                                          <span className="dotbtn">
                                                            <img
                                                              src={common.loadImg(
                                                                "ticksymbol.svg"
                                                              )}
                                                              alt=""
                                                            />
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              ) : (
                                                <td>
                                                  <div className="SubscriptionCnt1 lastborder">
                                                    <div className="bghead1">
                                                      <div>
                                                        <p className="register-step-head1"></p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </Fragment>
                                          ))}
                                        </tr>
                                      )
                                    )}

                                    <tr>
                                      <td style={{ width: "20px" }}></td>
                                      <td></td>
                                      {result.plan.map((i, k) => {
                                        let planStatus =
                                          result?.payment?.status;
                                        return (
                                          <td key={k}>
                                            {isUpgrade(i._id) ? (
                                              <div
                                                className="plan-btn plan-btn-upgrade"
                                                onClick={() =>
                                                  navigate(
                                                    `/agent/payment/${i._id}`
                                                  )
                                                }
                                              >
                                                <label>Upgrade Now</label>
                                              </div>
                                            ) : i.currentPlan &&
                                              planStatus === "grace" ? (
                                              <div
                                                className="plan-btn plan-btn-renew"
                                                onClick={() =>
                                                  navigate(
                                                    `/agent/payment/${i._id}`
                                                  )
                                                }
                                              >
                                                <label>Renew Now</label>
                                              </div>
                                            ) : (
                                              i.name !== "basic" &&
                                              ["grace", "expired"].includes(
                                                planStatus
                                              ) && (
                                                <div
                                                  className="plan-btn plan-btn-upgrade"
                                                  onClick={() =>
                                                    navigate(
                                                      `/agent/payment/${i._id}`
                                                    )
                                                  }
                                                >
                                                  <label>Activate Now</label>
                                                </div>
                                              )
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Payment History</h3>
                  </div>
                  <div className="mb-3">
                    <AgTable
                      module="agentPayments"
                      query={{ attrC: "subscription" }}
                      filterData={{
                        dateA: {
                          key: "startDate",
                          value: "",
                          title: "Created Date From",
                        },
                        dateB: {
                          key: "endDate",
                          value: "",
                          title: "Created Date To",
                        },
                      }}
                      onAction={() => {}}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default Info;
