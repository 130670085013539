export const message = {
  register_success:"Registered Successfully, please login",
  reset_password_success:"Password has been changed , please Login",
  inverify_account_title:"Your Account Unverified",
  inverify_account_message:"your account is on progressing to verified by admin , for more info contact support team",
  aa: "Hello",
  ab: "",
  ac: "",
  ad: "",
  ae: "",
  af: "",
  ag: "",
  ah: "",
  ai: "",
  aj: "",
  ak: "",
  al: "",
  am: "",
  an: "",
  ao: "",
  ap: "",
  aq: "",
  ar: "",
  as: "",
  at: "",
  au: "",
  av: "",
  aw: "",
  ax: "",
  ay: "",
  az: "",
  ba: "Sorry this course is already selected, please choose some other courses",
  bb: "You have not completed your profile please complete and select the profile",
  bc: "Course has been added",
  bd: "You have reached maximum course selection limit",
  be: "",
  bf: "",
  bg: "",
  bh: "",
  bi: "",
  bj: "",
  bk: "",
  bl: "",
  bm: "",
  bn: "",
  bo: "",
  bp: "",
  bq: "",
  br: "",
  bs: "",
  bt: "",
  bu: "",
  bv: "",
  bw: "",
  bx: "",
  by: "",
  bz: "",
  ca: "Something went wrong please try again",
  cb: "Removed successfully",
  cc: "",
  cd: "",
  ce: "",
  cf: "",
  cg: "",
  ch: "",
  ci: "",
  cj: "",
  ck: "",
  cl: "",
  cm: "",
  cn: "",
  co: "",
  cp: "",
  cq: "",
  cr: "",
  cs: "",
  ct: "",
  cu: "",
  cv: "",
  cw: "",
  cx: "",
  cy: "",
  cz: "",
};
