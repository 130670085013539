import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
// includes
import { common, types } from "helpers";
// pages
import CommonStudentForm from "includes/studentForm";
import CommonStudentView from "includes/studentView";

function Form() {
  const navigate = useNavigate();
  const { id, type, from } = useParams();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // functions
  const gotoStep = (page) => {
    navigate(`/application/profile/${id}/${page}`);
  };

  return (
    <Fragment>
      {authInfo.role === "student" ? (
        <Fragment>
          <div className="title-wrap sticky-top">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={common.loadImg("back-icn.svg")}
                  className="ed-cp pe-4"
                  alt=""
                  onClick={() => navigate(-1)}
                />
                {/* <h3 className="inner-page-header-title mb-0 me-1">
                  
                </h3> */}
                <h3 className="inner-page-header-title mb-0">
                Application Profile - {types.profileHeader[type]}
                </h3>
              </div>
              <div>
                <div
                  className="report-issue text-dec-none ed-cp"
                  onClick={() => navigate(-1)}
                >
                  <span>Cancel</span>
                  <img
                    src={common.loadImg("close-icn.svg")}
                    alt=""
                    className="ms-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrap">
            <CommonStudentView
              id={id}
              formModule={type}
              formType="application"
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="title-wrap sticky-top">
            <div className="inner-page-header-agent">
              <Row className="row d-flex justify-content-between align-items-center">
                <Col lg={4} md={5} className="application-heading">
                  <Link to="" onClick={() => navigate(-1)}>
                    <img
                      src={common.loadImg("back-icn.svg")}
                      alt=""
                      className="et-cp me-3"
                    />
                  </Link>
                  Edit - Application Profile
                </Col>
                <Col
                  lg={4}
                  md={4}
                  className="d-flex align-items-center justify-content-center dottedclsnew"
                >
                  {types.agentProfile.map((i, k) => (
                    <div
                      key={k}
                      className="cp"
                      onClick={() => gotoStep(i.type)}
                    >
                      <p className="register-step-head mb-1 me-2">
                        <span className={`dotbtn${i.type !== type ? 1 : ""}`}>
                          0{++k}
                        </span>
                      </p>
                    </div>
                  ))}
                </Col>
                <Col lg={4} md={3} className="endtext">
                  {/* <Link to="#" className="report-issue">
                  Save and Close
                  <img
                    src={common.loadImg("close-icn.svg")}
                    alt="arrow"
                    className="et-cp ms-1"
                  />
                </Link> */}
                </Col>
              </Row>
            </div>
          </div>

          <div className="content-wrap">
            {type !== "document" ? (
              <CommonStudentForm
                studentId={id}
                formType="application"
                formModule={type}
                formIndex="all"
                formAddNew={true}
                formModal={false}
                onSuccess={(id) => {
                  common.notify("S", "Profile updated successfully");
                }}
              />
            ) : (
              <Fragment>
                <CommonStudentView
                  id={id}
                  formType="application"
                  formModule="document"
                  onSuccess={() => navigate("/agent/student/list")}
                />
                <div className="register-step-footernew">
                  <Link to="/agent/student/list">
                    <button className="btn-default">Done</button>
                  </Link>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Form;
