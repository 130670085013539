import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { common } from "helpers";

const StatusTable = (props) => {
  return props.status == "process" ? (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <div lg={12} className="et-status-wrap text-center">
        <img
          src={common.loadImg("edutech-loader.gif")}
          className="loader-img"
          alt="application-icn"
        />
      </div>
    </Container>
  ) : props.status == "empty" ? (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <div className="et-status-wrap text-center">
        <img
          src={common.loadImg("no-data-found.png")}
          className="img-fluid"
          alt="application-icn"
        />
        <h3 className="et-status-txt pt-4">No Data Found!</h3>
      </div>
    </Container>
  ) : (
    props.status == "error" && (
      <Container
        fluid
        className="loader-wrap py-5 d-flex justify-content-center align-items-center"
      >
        <div lg={12} className="et-status-wrap text-center">
          <img
            src={common.loadImg("network-issue.png")}
            className="img-fluid"
            alt="application-icn"
          />
          <h3 className="et-status-txt pt-4">Something went wrong</h3>
        </div>
      </Container>
    )
  );
};

export default StatusTable;
