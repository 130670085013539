import React, { useState, useEffect, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Modal from "react-modal";
// includes
import { api, common, message } from "helpers";
import StatusBar from "elements/statusBar";
import moment from "moment";
import PaymentForm from "includes/paymentForm";

function FeesPayment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // props
  let { idA } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [display, setDisplay] = useState("A");
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authData] = useState(common.getAuth());
  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "application_order",
      method: "GET",
      query: `/${idA}?query=${JSON.stringify({
        feePaymentStatus: ["not_paid"],
      })}`,
    };
    api.call(data, (res) => {
      if (res.status === 200 && res.data?.length) {
        setResult(res.data);
        setStatus("success");
      } else {
        common.notify("W", "Something went wrong");
        navigate("/application/list");
      }
    });
  };

  const onSuccess = () => {
    common.notify("S", "Registration fees has been succesfully paid");
    navigate(`/application/list`);
  };

  //render
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(`/application/list`)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Registration Fee Payment - ID {idA}
            </h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            {display === "A" && (
              <div className="profile-notification mb-4">
                <div className="et-border-bottom">
                  <div className="row mx-0">
                    <div className="col-md-9 d-flex justify-content-start">
                      <div className="p-3 ps-4 pe-4">
                        <div className="date-time d-flex justify-content-start">
                          Selected Course
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {result.map((i, k) => (
                  <div className="et-border-bottom" key={k}>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="row align-items-center">
                          <div className="col-md-2 p-4 ps-5">
                            <img
                              src={common.cloudImg(i.course.university?.logo)}
                              alt="univ-img"
                              className="univ-prev-img"
                            />
                          </div>
                          <div className="col-md-6 p-4 courseborder">
                            <div className="course-heading date-time">
                              course
                            </div>
                            <div className="course-font">{i.course.name}</div>
                          </div>
                          <div className="col-md-4 p-4 courseborder">
                            <div className="course-heading date-time">
                              Year & Month
                            </div>
                            <div className="course-font">
                              {i.year}{" "}
                              {common.getMonthList()[i.month - 1].label}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 pt-4 px-4 pb-4 semclass courseborder">
                        <div className="course-heading date-time">
                          Course Fee
                        </div>
                        <div className="course-font">
                          <b>{i.course.tuitionFee}</b> {i.course.currency} /
                          Annual
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <PaymentForm
              auth={true}
              category="application_fee"
              taxKey="applicationFees"
              promo={false}
              id={idA}
              onSuccess={onSuccess}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
export default FeesPayment;
