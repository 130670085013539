import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// includes
import { config } from "helpers";
import StripeForm from "elements/stripeForm";
// const
const stripePromise = loadStripe(config.stripe.publishableKey);

function StripePayment(props) {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        status={props.status}
        onSuccess={(res) => {
          props.onSuccess(res);
        }}
        onCancel={props.onCancel}
      />
    </Elements>
  );
}

export default StripePayment;
