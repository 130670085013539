import React, { useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Modal from "react-modal";
// includes
import { common } from "helpers";

function StripeForm(props) {
  // const
  const stripe = useStripe();
  const elements = useElements();
  const options = {
    style: {
      base: {
        fontSize: "18px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
  // state
  const [init, setInit] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  // effect
  useEffect(() => {
    if (init) {
      setError(props.status.error);
      setLoading(false);
    } else {
      setInit(true);
    }
  }, [props.status]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    } else {
      setError("");
      setLoading(true);
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (payload.error) {
        setTimeout(() => {
          setError(payload.error.message);
          setLoading(false);
        }, 500);
      } else {
        props.onSuccess(payload);
      }
    }
  };

  return (
    <Modal
      isOpen={true}
      style={common.modalStyle(50)}
      contentLabel=""
      ariaHideApp={false}
    >
      <div className="register-step-header d-flex justify-content-between align-items-center">
        <p className="register-step-title mb-0">Payment</p>
        {!loading && (
          <div className="cp" onClick={props?.onCancel}>
            <img src={common.loadImg("close-icn.svg")} alt="" />
          </div>
        )}
      </div>
      <div className="stripe-wraper">
        <div className="DemoPickerWrapper">
          <form onSubmit={handleSubmit}>
            {error && (
              <div className="alert-msg error d-flex align-items-center mt-4">
                <img
                  src={common.loadImg("close-icn.svg")}
                  alt="alert-tyle"
                  className="me-2 cp"
                  onClick={() => setError("")}
                />
                <label>{error}</label>
              </div>
            )}

            <div className="form-row">
              <label className="label-padding">Card number</label>
              <CardNumberElement
                options={options}
                onChange={() => setError("")}
              />
              <div className="d-flex align-items-center mt-3">
                <img
                  src={common.loadImg("visa-icn.svg")}
                  alt=""
                  className="me-3"
                />
                <img
                  src={common.loadImg("mastercard-icn.svg")}
                  alt=""
                  className="me-3"
                />
                <img
                  src={common.loadImg("maestro-icn.svg")}
                  alt=""
                  className="me-3"
                />
                <img
                  src={common.loadImg("discover-icn.svg")}
                  alt=""
                  className="me-3"
                />
                <img
                  src={common.loadImg("jcb-icn.svg")}
                  alt=""
                  className="me-3"
                />
                <img
                  src={common.loadImg("ae-icn.svg")}
                  alt=""
                  className="me-3"
                />
              </div>
            </div>

            <div className="form-row">
              <label className="label-padding">Expiration date</label>
              <CardExpiryElement
                options={options}
                onChange={() => setError("")}
              />
            </div>

            <div className="form-row">
              <label className="label-padding">CVC</label>
              <CardCvcElement options={options} onChange={() => setError("")} />
            </div>
            <div className="form-row register-step-agent-footer text-center">
              <button
                type="submit"
                disabled={!stripe || loading}
                className="btn-default"
              >
                {loading ? "Processing..." : "Pay Now"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default StripeForm;
