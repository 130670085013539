import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
// includes
import StatusBar from "elements/statusBar";
import { common, types } from "helpers";

function Progress() {
  // props
  const navigate = useNavigate();
  const { id, type, from } = useParams();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Edit Application Profile
            </h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        {userInfoStatus !== "success" ? (
          <StatusBar status={userInfoStatus} />
        ) : (
          <Fragment>
            <Container className="p-0">
              <Row>
                <Col lg={12} className="mx-auto">
                  <ul className="row">
                    {types.studentProfile.map((i, k) => (
                      <li className="col-md-6 mt-2" key={k}>
                        <div
                          className={`profile-home-header p-4 cp`}
                          onClick={() =>
                            navigate(`/application/profile/${id}/${i.type}`)
                          }
                        >
                          <img src={common.loadImg(i.icon)} alt="" />
                          <div className="d-flex justify-content-between align-items-end">
                            <div>
                              <h4 className="profile-card-title mb-0 mt-3">
                                {i.title}
                              </h4>
                              <p className="profile-card-tagline mb-0">
                                {i.description}
                              </p>
                            </div>
                            <div>
                              <Link to="">
                                <img
                                  src={common.loadImg("arrow-right-circle.svg")}
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
export default Progress;
