import React, { Fragment, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
// includes
import { common, types } from "helpers";
import ProgressBar from "elements/progressBar";
import StatusBar from "elements/statusBar";

function Progress() {
  // props
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  return (
    <div className="content-wrap no-header">
      {userInfoStatus !== "success" ? (
        <StatusBar status={userInfoStatus} />
      ) : (
        <Fragment>
          <Container className="p-0">
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="profile-home-header p-4">
                  <Row>
                    <Col md={6}>
                      <div className="d-flex align-items-center mb-3">
                        {/* <img src={common.loadImg("avatar.png")} alt="" /> */}
                        <div className="profile-icn-wrap">{authInfo?.firstName[0]}</div>
                        <h3 className="profile-name ms-3 mb-0">
                          Hi! {authInfo?.firstName}
                        </h3>
                      </div>
                      <div>
                        <p className="complete-profile-txt mb-1">
                          Complete your profile
                        </p>
                        <h3 className="start-journey-txt">
                          Start your journey with U&S
                        </h3>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-end align-items-center profile-progress-wrap"
                    >
                      <ProgressBar progress={userInfo?.progress?.total} />
                    </Col>
                  </Row>
                </div>
                <ul className="ed-student-profile-list mt-4 row">
                  {types.studentProfile.map((i, k) => (
                    <li
                      className="col-md-6 mt-2"
                      key={k}
                    >
                      <div
                        className={`profile-home-header p-4 cp ${
                          userInfo.progress[i.key].completed >=
                          userInfo.progress[i.key].total
                            ? "status-completed"
                            : ""
                        }`}
                        onClick={() => navigate(`/student/profile/${i.type}`)}
                      >
                        <img src={common.loadImg(i.icon)} alt="" />
                        <div className="d-flex justify-content-between align-items-end">
                          <div>
                            <h4 className="profile-card-title mb-0 mt-3">
                              {i.title}
                            </h4>
                            <p className="profile-card-tagline mb-0">
                              {i.description}
                            </p>
                          </div>
                          <div>
                            <Link to="">
                              {userInfo.progress[i.key].completed >=
                              userInfo.progress[i.key].total ? (
                                <img
                                  src={common.loadImg("completed-icn.svg")}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={common.loadImg("arrow-right-circle.svg")}
                                  alt=""
                                />
                              )}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </Fragment>
      )}
    </div>
  );
}
export default Progress;
