import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { common } from "helpers";

const StatusBar = (props) => {
  return props.status == "process" ? (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col lg={12} className="text-center">
          <img
            src={common.loadImg("edutech-loader.gif")}
            className="loader-img"
            alt="application-icn"
          />
          {props?.message && (
            <h3 className="nodata-found-txt pt-4">
              {props.message === "default"
                ? "Loading data please wait"
                : props.message}
            </h3>
          )}
        </Col>
      </Row>
    </Container>
  ) : props.status == "empty" ? (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col lg={12} className="text-center">
          <img
            src={common.loadImg("no-data-found.png")}
            className="img-fluid"
            alt="application-icn"
          />
          <h3 className="nodata-found-txt pt-4">No Data Found!</h3>
        </Col>
      </Row>
    </Container>
  ) : (
    props.status == "error" && (
      <Container
        fluid
        className="loader-wrap py-5 d-flex justify-content-center align-items-center"
      >
        <Row>
          <Col lg={12} className="text-center">
            <img
              src={common.loadImg("no-data.png")}
              className="img-fluid"
              alt="application-icn"
            />
            <h3 className="nodata-found-txt pt-4">Something went wrong</h3>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default StatusBar;
