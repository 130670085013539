import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ModalInfo(props) {
  return (
    <Modal show={true} onHide={props?.onYes} size={"lg"} centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.data.message}</Modal.Body>
      {props?.data?.footerHide ? null : (
        <Modal.Footer>
          <Button variant="secondary" onClick={props?.onYes}>
            Done
          </Button>
        </Modal.Footer>
      )}
      <div className="shadow-div m-0"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default ModalInfo;
