import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import { common } from "helpers";
import PaymentForm from "includes/paymentForm";

function CoursePayment() {
  // props
  let { idA } = useParams();
  const navigate = useNavigate();
  // state
  const [authData] = useState(common.getAuth());

  // handler
  const onSuccess = () => {
    common.notify("Thankyou for the payment!");
    navigate(`/application/status/${idA}`);
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Course Payment
            </h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        <PaymentForm
          auth={true}
          category="course_fee"
          taxKey="courseFees"
          promo={authData.accountType === "novelty"}
          id={idA}
          onSuccess={onSuccess}
        />
      </div>
    </Fragment>
  );
}

export default CoursePayment;
