import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { types } from "helpers";
import { Fragment } from "react";

function List() {
  const navigate = useNavigate();
  return (
    <AgTable
      module="studentConnect"
      filterData={{
        dateA: { key: "startDate", value: "", title: "Connected Date From" },
        dateB: { key: "endDate", value: "", title: "Connected Date To" },
      }}
      onAction={() => {}}
    />
  );
}

export default List;
