import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { Fragment } from "react";

export default function List() {
  const navigate = useNavigate();
  const onActionA = (data) => {
    if (data.type === "Agent Info") {
      navigate(`/agent/promoters/view/${data.item._id}`);
    } else if (data.type === "Student List") {
      navigate(`/agent/promoters/student/${data.item._id}`);
    } else if (data.type === "Application List") {
      navigate(`/agent/promoters/application/${data.item._id}`);
    }
  };
  return (
    <Fragment>
      {true && (
        <AgTable
          module="promoters"
          filterData={{
            status: { key: "isActive", value: "" },
            // verified: { key: "isVerified", value: "" },
          }}
          onAction={onActionA}
        />
      )}
    </Fragment>
  );
}
