import React from "react";
import Modal from "react-modal";
import { Container, Row, Col } from "react-bootstrap";
import { common } from "helpers";

function ModalNotVerified(props) {
  return (
    <Modal
      isOpen={true}
      style={common.modalStyle(40)}
      ariaHideApp={false}
      contentLabel="Modal"
    >
      <Container fluid>
        <Row className="row-border1">
          <Col md={12}>
            <div className="text-center p-5">
              <div>
                <img
                  src={common.loadImg("acc-not-verified.svg")}
                  className="me-3 ms-1"
                  alt="not-verified"
                />
              </div>
              <h3 className="acc-status-title">Account not Verified</h3>
              <p className="acc-status-txt">
                Currently we're verifying your account and will notify your
                email once it is completed
              </p>
              <div>
                <button
                  className="register-step-btn"
                  onClick={props?.onClose}
                >
                  Yeah! Ok
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="shadow-div m-0"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default ModalNotVerified;
