import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import { api, common } from "helpers";
import StatusBar from "elements/statusBar";
import PaymentForm from "includes/paymentForm";

function Payment() {
  const navigate = useNavigate();
  let { id } = useParams();
  // state
  const [status, setStatus] = useState("process");
  const [count, setCount] = useState(0);
  const [error, setError] = useState(0);
  const [result, setResult] = useState({
    plan: [],
    payment: {},
  });
  
  // effect
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (count === 2) {
      if (error === 0) {
        checkPayment();
      } else {
        setStatus("error");
      }
    }
  }, [count]);

  // init
  const initData = () => {
    getPlan();
    getPayment();
  };

  // api
  const getPlan = () => {
    let data = {
      url: "agent_plan",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let data = res.data?.data;
        let dataA = {};
        data.map((i) => {
          dataA[i._id] = i.name;
        });
        setResult((prev) => ({ ...prev, plan: data, subscription: dataA }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const getPayment = () => {
    let data = {
      url: "agent_payment",
      query: "?limit=1&offset=1&sortBy=_id&sortDir=desc",
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, payment: res?.data?.data?.[0] }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const checkPayment = () => {
    let error = "";
    let paymentStatus = result?.payment?.status;
    if (result.subscription[id] === "basic") {
      error = "This plan is not allowed";
    } else if (paymentStatus === "active") {
      if (result.subscription[id] === result.payment.planId?.name) {
        error = "You are already in this plan";
      } else if (!allowUpgrade()) {
        error = "This plan is not allowed for upgrade";
      }
    }

    // check
    if (error) {
      common.notify("E", error);
      navigate("/agent/account/info");
    } else {
      setStatus("success");
    }
  };

  const allowUpgrade = () => {
    let obj = Object.keys(result.subscription);
    return obj.indexOf(id) > obj.indexOf(result.payment?.planId?._id);
  };

  // handler
  const onSuccess = () => {
    navigate("/agent/account/info");
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Subscription & Payment
            </h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <PaymentForm
            auth={true}
            category="subscription"
            taxKey="subscription"
            promo={false}
            id={id}
            onSuccess={onSuccess}
          />
        )}
      </div>
    </Fragment>
  );
}

export default Payment;
