import { useNavigate, Link, useOutletContext } from "react-router-dom";
import { common } from "helpers";
// includes
import AgTable from "elements/AgTable";
import { types } from "helpers";
import { Fragment, useState } from "react";
import ModalConfirm from "elements/modalConfirm";
import { api } from "helpers";
import VerifyModal from "elements/modalNotVerified";

function List() {
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(null);

  const onAction = (data) => {
    if (data.type === "Add") {
      navigate(`/agent/member/add`);
    } else if (data.type === "Edit") {
      navigate(`/agent/member/edit/${data.item._id}`);
    } else if (data.type === "View") {
      navigate(`/agent/member/view/${data.item._id}`);
    } else if (data.type === "Delete") {
      setDeleteData(data.item);
    }
  };

  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "subagent",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 204) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setReload(new Date());
        setLoading(false);
      }
    });
  };

  const userVerify = (val) => {
    if(authInfo?.role === "agent"){
    api.call(
      {
        url: "agent_profile",
        method: "GET",
      },
      (res) => {
        if (res.status === 200) {
          if(res?.data.isVerified){
            navigate(`/agent/member/add`);
          } else {
            setIsModal(true);
          }
        }
      }
    );
    } else {
        navigate(`/agent/member/add`);
    }
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center py-2">
        <h1 className="page-title">Members</h1>
        {/* <Link to="/agent/member/add" className="text-dec-none"> */}
          <button onClick={() => userVerify()} className="btn-signin d-flex justify-content-between align-items-center">
            <span className="ms-3">New Member</span>
            <img
              src={common.loadImg("plus-sign.svg")}
              className="me-3"
              alt="promotion-icn"
            ></img>
          </button>
        {/* </Link> */}
      </div>
      <div className="content-wrap">
        <AgTable
          module="agentMember"
          reload={reload}
          filterData={{
            status: { key: "isActive", value: "" },
          }}
          onAction={onAction}
        />
      </div>
      {deleteData !== null && (
          <ModalConfirm
            loading={loading}
            onYes={() => DeleteRecord(deleteData)}
            onNo={() => setDeleteData(null)}
          />
        )}
        {isModal && (
        <VerifyModal onClose={() => setIsModal(null)} />
      )}
    </Fragment>
  );
}

export default List;
